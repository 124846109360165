import React from 'react'
import './style.scss'
//import NaviContext from '../../../context/NaviContext'

function Logo(props) {
	//const naviContext = useContext(NaviContext)

  return (
		<div className='logo master-logo x1'>
			<div className='logo' />
			IWM
		</div>
  )
}

export default Logo